import React from 'react';
import { EntityToolTip, TooltipPortal } from './basic_header_fields.js.jsx';
import anchorme from 'anchorme';
import TextArea from './management_site/components/TextArea.tsx';

/*
  + type ( The folowing types are supported: 'checkbox', 'select', 'radio', 'textarea', 'memo', 'switch', 'text', 'number', 'date', 'time', 'combodate' (date/time), 'bubble', 'readonly', 'hidden_on_edit' )
  + title
  + displayValue
  + value
  + fieldName
  + menuItems ( 2D array. This is used for select or radio types. Or checklists sometimes)
      => [ [itemName, itemValue, dataAttrs, opts], ...]
  + handleChange ( A function to be called on value change)
  + editOnly (optional)
  + displayOnly (optional)
  + skipFormatMoneyListener (optional)
*/
export default class DataBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      showNotesForm: false,
      expanded: false,
      isOpen: false,
    };
    this.unsetStyle = {
      position: 'unset',
    };
    this.handleEditLabelClick = this.handleEditLabelClick.bind(this);
    this.handleAddNoteClick = this.handleAddNoteClick.bind(this);
    this.handleNoteSaveClick = this.handleNoteSaveClick.bind(this);
    this.handleNoteDeleteClick = this.handleNoteDeleteClick.bind(this);
    this.toggleOrSetOpen = this.toggleOrSetOpen.bind(this);
    this.handleToggleToolTip = this.handleToggleToolTip.bind(this);
    this.ref = React.createRef();
  }

  componentWillReceiveProps(newProps) {
    const that = this;
    this.setState(newProps, function () {
      if (
        this.state.fieldName ==
        'building[custom_domain_attributes][domain_name]'
      ) {
        $('#custom-domain-anchor').html(
          anchorme({
            input: this.state.displayValue,
            options: {
              attributes: {
                class: 'pm-display-values--value pm-text-color--blue1',
                style: 'text-decoration: none',
                target: '_blank',
              },
            },
          })
        );
      }
    });
    this.setState(newProps);
  }

  reinitDateListeners() {
    if (
      typeof this.input !== 'undefined' &&
      $(this.input).hasClass('pm-datepicker-field')
    ) {
      initReactDatepicker(this.input, this.state.handleChange);
    }
    if (
      typeof this.input !== 'undefined' &&
      $(this.input).hasClass('pm-datetimepicker-field')
    ) {
      initReactDateTimepicker(this.input, this.state.handleChange);
    }
    if (
      typeof this.input !== 'undefined' &&
      $(this.input).hasClass('pm-timepicker-field')
    ) {
      initReactTimepicker(this.input, this.state.handleChange);
    }
  }

  componentDidMount() {
    this.reinitDateListeners();

    if (!this.props.skipFormatMoneyListener) {
      attachFormatMoneyListener();
    }

    //init Select2 Menu for native select menus
    if (
      this.state.type == 'select' &&
      (this.state.nativeInput == 'true' ||
        this.state.nativeInput == 'grouped') &&
      typeof this.input !== 'undefined' &&
      typeof jQuery.fn.select2 !== 'undefined'
    ) {
      if (this.state.multiple && this.state.fieldName == 'selected_tenants') {
        $(this.input)
          .select2({ placeholder: 'Select tenants...' })
          .on('select2:select', this.state.handleChange);
        $('#selected_tenants')
          .data('select2')
          .$dropdown.addClass('multiple-select-dropdown');
      } else {
        $(this.input).select2().on('select2:select', this.state.handleChange);
      }
      if (this.state.multiple) {
        $(this.input).select2().on('select2:unselect', this.state.handleChange);
      }
    } else if (this.state.type == 'select') {
      $(this.input).select2().on('select2:select', this.state.handleChange);
    }

    if (
      this.state.fieldName == 'building[custom_domain_attributes][domain_name]'
    ) {
      $('#custom-domain-anchor').html(
        anchorme({
          input: this.state.displayValue,
          options: {
            attributes: {
              class: 'pm-display-values--value pm-text-color--blue1',
              style: 'text-decoration: none',
              target: '_blank',
            },
          },
        })
      );
    }
  }

  componentDidUpdate() {
    this.reinitDateListeners();
    if (
      this.state.type == 'select' &&
      (this.state.nativeInput == 'true' ||
        this.state.nativeInput == 'grouped') &&
      typeof this.input !== 'undefined' &&
      typeof jQuery.fn.select2 !== 'undefined'
    ) {
      if (!$(this.input).hasClass('select2-hidden-accessible')) {
        $(this.input)
          .select2()
          .off('select2:select')
          .on('select2:select', this.state.handleChange);
        if (this.state.multiple) {
          $(this.input)
            .select2()
            .off('select2:unselect')
            .on('select2:unselect', this.state.handleChange);
        }
      } else if (this.state.fieldName == '[maintenance_request][tenant_ids]') {
        // Maintenance tenant list needs to be reinitialized to display updated options after site changes
        $(this.input)
          .select2()
          .off('select2:select')
          .on('select2:select', this.state.handleChange);
      }
    }
  }

  toggleOrSetOpen(open) {
    this.setState((prevState) => ({
      isOpen: open == undefined ? !prevState.isOpen : open,
    }));
  }

  handleToggleToolTip(e) {
    this.toggleOrSetOpen();
  }

  handleAddNoteClick(e) {
    this.setState({ showNotesForm: true }, () => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  handleNoteSaveClick(e) {
    this.props.handleNoteClick(e, 'create', () => {
      this.setState({ showNotesForm: false }, () => {
        window.dispatchEvent(new Event('resize'));
      });
    });
  }

  handleNoteDeleteClick(e) {
    confirm('Are you sure you want to delete this note?', (ok) => {
      if (ok) {
        this.props.handleNoteClick(e, 'destroy', () => {
          this.setState({ showNotesForm: false }, () => {
            window.dispatchEvent(new Event('resize'));
          });
        });
      }
    });
  }

  handleEditLabelClick(e) {
    e.stopPropagation();
  }

  render() {
    const that = this;
    const style = {};
    const actualFieldName = this.state.fieldName;
    const htmlFieldName = this.props.overrideFieldName || this.state.fieldName;
    if (this.props.widthOverride) {
      style['width'] = this.props.widthOverride;
    }
    if (this.state.type == 'hidden') {
      return (
        <input
          type="hidden"
          name={htmlFieldName}
          data-field-name={actualFieldName}
          defaultValue={this.state.value}
        />
      );
    } else if (this.state.type == 'checkbox') {
      var checked =
        typeof this.state.value !== 'undefined' &&
        this.state.value != null &&
        (this.state.value.toString().toLowerCase() == 'yes' ||
          this.state.value.toString().toLowerCase() == 'true');
      return (
        <div
          className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}
          style={style}
        >
          <div className={'mdc-form-field ' + this.state.formFieldClassNames}>
            <div
              id="mdc-js-checkbox"
              className="mdc-checkbox"
              data-mdc-auto-init="MDCCheckbox"
            >
              <input
                type="checkbox"
                id={`checkbox-${this.state.fieldName}-${this.state.valueName}`}
                className="mdc-checkbox__native-control"
                name={htmlFieldName}
                data-field-name={actualFieldName}
                data-value-name={this.state.valueName}
                onChange={this.state.handleChange}
                data-groupkey={this.state.groupKey}
                checked={checked}
              />
              <div className="mdc-checkbox__background">
                <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                  <path
                    className="mdc-checkbox__checkmark-path"
                    fill="none"
                    stroke="white"
                    d="M1.73,12.91 8.1,19.28 22.79,4.59"
                  />
                </svg>
                <div className="mdc-checkbox__mixedmark"></div>
              </div>
            </div>
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={`checkbox-${this.state.fieldName}-${this.state.valueName}`}
            >
              {this.state.title}
            </label>
          </div>
        </div>
      );
    } else if (this.state.type == 'checklist') {
      if (typeof this.state.menuItems === 'undefined') {
        return (
          <div
            className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}
          ></div>
        );
      }

      var me = this;
      var checkboxlist = this.state.menuItems.map(
        function (listItem, listIndex) {
          if (Array.isArray(listItem)) {
            var itemName = listItem[0];
            var itemValue = listItem[1];
          } else {
            var itemName = listItem;
            var itemValue = listItem;
          }
          var checked =
            (me.state.value instanceof Array &&
              me.state.value.indexOf(itemValue) != -1) ||
            me.state.value == itemValue;
          return (
            <div
              key={me.state.fieldName + 'checkListOption' + listIndex}
              className={'mdc-form-field ' + me.state.formFieldClassNames}
            >
              <div
                id="mdc-js-checkbox"
                className="mdc-checkbox"
                data-mdc-auto-init="MDCCheckbox"
              >
                <input
                  type="checkbox"
                  id={'native-js-checkbox1-' + listItem}
                  className="mdc-checkbox__native-control"
                  name={htmlFieldName}
                  data-field-name={actualFieldName}
                  data-value={itemValue}
                  onClick={me.state.handleChange}
                  defaultChecked={checked}
                />
                <div className="mdc-checkbox__background">
                  <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                    <path
                      className="mdc-checkbox__checkmark-path"
                      fill="none"
                      stroke="white"
                      d="M1.73,12.91 8.1,19.28 22.79,4.59"
                    />
                  </svg>
                  <div className="mdc-checkbox__mixedmark"></div>
                </div>
              </div>
              <label
                onClick={that.handleEditLabelClick}
                htmlFor="native-js-checkbox1"
              >
                {itemName}
              </label>
            </div>
          );
        }
      );

      return (
        <div className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}>
          {checkboxlist}
        </div>
      );
    } else if (this.state.type == 'switch') {
      //Not a supported Custom Field Type (untested)
      var on =
        typeof this.state.value !== 'undefined' &&
        this.state.value != null &&
        (this.state.value.toString().toLowerCase() == 'yes' ||
          this.state.value.toString().toLowerCase() == 'true');
      return (
        <div className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}>
          <div className={'mdc-form-field ' + this.state.formFieldClassNames}>
            <div className="mdc-switch">
              <input
                type="checkbox"
                id="basic-switch1"
                className="mdc-switch__native-control"
                name={htmlFieldName}
                data-field-name={actualFieldName}
                onChange={this.state.handleChange}
                defaultChecked={on}
              />
              <div className="mdc-switch__background">
                <div className="mdc-switch__knob"></div>
              </div>
            </div>
            <label
              onClick={that.handleEditLabelClick}
              htmlFor="basic-switch1"
              className="mdc-switch-label"
            >
              {this.state.title}
            </label>
          </div>
        </div>
      );
    } else if (this.state.type == 'radio') {
      //Not a supported Custom Field Type (untested)
      if (typeof this.state.menuItems === 'undefined')
        return (
          <div
            className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}
          ></div>
        );

      var me = this;
      var radioOptions = this.state.menuItems.map(function (radioOption, i) {
        var itemName = radioOption[0];
        var itemValue = radioOption[1];
        var checked = me.state.value == itemValue;
        return (
          <div
            key={me.fieldName + '-option-' + i}
            className={'mdc-form-field ' + this.state.formFieldClassNames}
          >
            <div className="mdc-radio">
              <input
                key={'radio-' + i}
                id={'radio-' + i}
                className="mdc-radio__native-control"
                type="radio"
                name={itemName}
                data-value={itemValue}
                onClick={me.state.handleChange}
                defaultChecked={checked}
              />
              <div className="mdc-radio__background">
                <div className="mdc-radio__outer-circle"></div>
                <div className="mdc-radio__inner-circle"></div>
              </div>
            </div>
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={'radio-' + i}
              id={'radio-' + i + '-label'}
            >
              {itemName}
            </label>
          </div>
        );
      });
      return (
        <div className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}>
          {radioOptions}
        </div>
      );
    } else if (
      this.state.type == 'select' &&
      (this.state.nativeInput == 'true' ||
        this.state.nativeInput == 'grouped') &&
      !this.state.displayOnly
    ) {
      if (typeof this.state.menuItems === 'undefined') {
        var editField = (
          <div className="pm-select" role="listbox" tabIndex="0">
            <label onClick={that.handleEditLabelClick}>
              {this.state.title}
            </label>
            <select
              className="pm-default-select2-menu data-block-select2 mdc-select pm-select-small"
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={this.state.value || ''}
              ref={(input) => {
                this.input = input;
              }}
              onChange={this.state.handleChange}
            ></select>
          </div>
        );
      } else {
        var me = this;
        var selectMenuOptions = [];
        if (this.state.nativeInput == 'grouped') {
          var groups = Object.keys(me.state.menuItems);
          if (
            groups.includes('All Vendors') &&
            groups.includes('Suggested Vendors')
          ) {
            groups.reverse();
          }
          groups.forEach(function (groupName) {
            var groupItems = [];
            me.state.menuItems[groupName].map(function (option) {
              if (Array.isArray(option)) {
                var itemName = option[0];
                var itemValue = option[1];
              } else {
                var itemName = option;
                var itemValue = option;
              }
              var additionalAttrs = {};
              var dataAttributes = option[2];
              if (dataAttributes != null) {
                for (var i = 0; i < dataAttributes.length; i++) {
                  if (dataAttributes[i].name != undefined)
                    additionalAttrs[dataAttributes[i].name] =
                      dataAttributes[i].value;
                }
              }
              if (
                typeof option[3] !== 'undefined' &&
                typeof option[3]['disabled'] !== 'undefined'
              ) {
                additionalAttrs['disabled'] = option[3]['disabled'];
              }
              if (
                !(
                  typeof option[3] !== 'undefined' &&
                  typeof option[3]['disabled'] !== 'undefined' &&
                  option[3]['disabled']
                )
              ) {
                groupItems.push(
                  <option
                    {...additionalAttrs}
                    key={me.state.fieldName + 'select-option' + itemValue}
                    name={me.state.fieldName}
                    value={itemValue || ''}
                    data-value={itemName}
                    onClick={me.state.handleChange}
                  >
                    {itemName}
                  </option>
                );
              }
            });
            selectMenuOptions.push(
              <optgroup
                key={me.state.fieldName + 'select-optgroup-' + groupName}
                label={groupName}
              >
                {' '}
                {groupItems}{' '}
              </optgroup>
            );
          });
        } else {
          var selectMenuOptions = [];

          if (this.state.fieldName == 'company_id') {
            selectMenuOptions = this.state.menuItems.filter(function (item) {
              return (
                item[2] == 'restricted_role' || item[2] == 'unrestricted_role'
              );
            });
          } else {
            selectMenuOptions = this.state.menuItems.filter(function (item) {
              return item[3] == null || item[3].disabled != true;
            });
          }
          selectMenuOptions = selectMenuOptions.map(function (option, idx) {
            var dataSiteString = null;
            if (Array.isArray(option)) {
              var itemName = option[0];
              if (typeof option[1] == 'object' && option[1] != null)
                var itemValue = option[1].text;
              else var itemValue = option[1];

              // Set the data-site-string attribute for the company select field on the transactions tab if there is only 1 unit or 1
              // building for that company. There are two possible formats for option:
              // 1) option = ['Company name', company_id, 'role restriction', building_id, unit_id, building_count, unit_count]
              // 2) option = ['Company name', company_id, 'role restriction', site_string]
              if (me.state.fieldName == 'company_id') {
                if (option.length == 4) {
                  dataSiteString = option[3];
                } else if (option.length > 6) {
                  if (option[6] == 1) {
                    dataSiteString = 'Unit_' + option[4];
                  } else if (option[5] == 1) {
                    dataSiteString = 'Building_' + option[3];
                  }
                }
              }
            } else {
              var itemName = option;
              var itemValue = option;
            }

            var additionalAttrs = {};
            var dataAttributes = option[2];
            if (dataAttributes != null && Array.isArray(dataAttributes)) {
              for (var i = 0; i < dataAttributes.length; i++) {
                if (dataAttributes[i].name != undefined)
                  additionalAttrs[dataAttributes[i].name] =
                    dataAttributes[i].value;
              }
            } else if (
              dataAttributes != null &&
              !Array.isArray(dataAttributes)
            ) {
              Object.keys(dataAttributes).forEach(function (data) {
                if (dataAttributes[data] != null && data.includes('data'))
                  additionalAttrs[data] = dataAttributes[data];
              });
            }

            return (
              <option
                {...additionalAttrs}
                key={me.state.fieldName + 'select-option' + idx}
                id={'select-option' + idx}
                className="mdc-list-item"
                role="option"
                value={itemValue || ''}
                name={htmlFieldName}
                data-field-name={actualFieldName}
                data-site-string={dataSiteString}
                data-value={itemValue}
                onClick={me.state.handleChange}
              >
                {itemName}
              </option>
            );
          });
        }
        var editField = (
          <div className="pm-relative" role="listbox">
            <div
              className={
                me.state.multiple ? 'pm-select pm-select-multiple' : 'pm-select'
              }
            >
              <label onClick={that.handleEditLabelClick}>
                {this.state.title}
              </label>
              <select
                className="pm-default-select2-menu data-block-select2 mdc-select pm-select-small"
                ref={(input) => {
                  this.input = input;
                }}
                id={me.state.fieldName}
                name={htmlFieldName}
                data-field-name={actualFieldName}
                multiple={me.state.multiple ? 'multiple' : null}
                onChange={me.state.handleChange}
                value={me.state.value || ''}
                data-charge-id="9"
              >
                {selectMenuOptions}
              </select>
            </div>
          </div>
        );
        if (this.state.toolTipIncluded == true) {
          let displayClassNames = 'pm-display';
          var address_info,
            fullName,
            pageType,
            email,
            phone,
            firstName,
            lastName,
            mi,
            role,
            active,
            taxId,
            securityDeposit,
            otherTenants;
          let noTooltipInfo = null;
          if (this.state.toolTipInfo != undefined) {
            pageType = this.state.title;
            email = this.state.toolTipInfo.email;
            phone = this.state.toolTipInfo.phone;
            firstName = this.state.toolTipInfo.firstname;
            lastName = this.state.toolTipInfo.lastname;
            mi = this.state.toolTipInfo.mi;
            role = this.state.toolTipInfo.role;

            if (this.state.title == 'Vendor') {
              fullName = this.state.toolTipInfo.vendorName;
              taxId = this.state.toolTipInfo.taxId;
              active = this.state.toolTipInfo.active;
            } else if (this.state.title == 'Tenant') {
              fullName = this.state.toolTipInfo.name;
              securityDeposit = this.state.toolTipInfo.securityDeposit;
              otherTenants = this.state.toolTipInfo.otherTenants;
            }

            address_info = {
              addressline1: this.state.toolTipInfo.addressline1,
              addressline2: this.state.toolTipInfo.addressline2,
              city: this.state.toolTipInfo.city,
              zipcode: this.state.toolTipInfo.zipcode,
              country: this.state.toolTipInfo.country,
              state: this.state.toolTipInfo.state,
            };
          } else {
            noTooltipInfo = true;
          }

          return (
            <div
              className={'pm-flex pm-flex-col ' + this.state.pmFlexClassNames}
              style={style}
            >
              <div className="pm-flex pm-flex-nowrap pm-flex-align-start">
                {/* Display text */}
                <div
                  data-custom-field-id={this.props.customFieldId}
                  className={displayClassNames}
                >
                  <span className="mdc-expansion__item-title data-block-title">
                    {this.state.title}
                  </span>
                  <span
                    title={this.state.displayValue}
                    className="mdc-expansion__item-value data-block-value"
                  >
                    {this.state.displayValue}
                  </span>
                </div>
                {/* Editing form field */}
                <div className="pm-edit">{editField}</div>
                <div
                  className="mdc-menu-anchor pm-tenantinfo-tooltip--parent pm-margin-left-20"
                  id="anchor-div"
                >
                  <button
                    className="pm-tenantinfo-tooltip-toggle pm-tenantinfo-tooltip-small mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-bg-color--blue1 pm-text-color--white"
                    data-mdc-auto-init="MDCRipple"
                    onClick={this.handleToggleToolTip}
                    ref={this.ref}
                  >
                    <i className="mdi mdi-menu-down"></i>
                  </button>
                  {this.state.isOpen && (
                    <TooltipPortal>
                      <EntityToolTip
                        address={address_info}
                        email={email}
                        number={phone}
                        pageType={pageType}
                        firstName={firstName}
                        lastName={lastName}
                        entityName={fullName}
                        role={role}
                        taxId={taxId}
                        active={active}
                        securityDeposit={securityDeposit}
                        entityId={this.state.value}
                        editable={false}
                        maintenancePage={this.props.maintenancePage}
                        otherTenants={otherTenants}
                        noInfo={noTooltipInfo}
                        title={this.state.title}
                        isVendor={this.props.isVendor}
                        isOpen={this.state.isOpen}
                        setIsOpen={this.toggleOrSetOpen}
                        buttonRef={this.ref}
                      />
                    </TooltipPortal>
                  )}
                </div>
              </div>
            </div>
          );
        }
      }
    } else if (this.state.type == 'select' && !this.state.displayOnly) {
      if (
        typeof this.state.menuItems === 'undefined' ||
        this.state.menuItems.length == 0
      ) {
        var editField = (
          <div
            className="mdc-select pm-margin-top-15"
            role="listbox"
            tabIndex="0"
            data-mdc-auto-init="MDCSelect"
          >
            <span className="mdc-select__selected-text">
              {this.state.displayValue}
            </span>
            <div className="mdc-menu mdc-select__menu">
              <ul className="mdc-list mdc-menu__items "></ul>
            </div>
          </div>
        );
      } else {
        var me = this;
        let menuItems = [];
        if (
          this.state.parentType === 'ManagementCharge' &&
          this.state.menuItems[0][0] === 'Recently Used'
        ) {
          for (var i = 0; i < this.state.menuItems.length; i++) {
            for (var j = 0; j < this.state.menuItems[i][1].length; j++) {
              menuItems.push(this.state.menuItems[i][1][j]);
            }
          }
        } else {
          menuItems = this.state.menuItems;
        }
        var selectMenuOptions = menuItems
          .filter(function (item) {
            return item[3] == null || item[3].disabled != true;
          })
          .map(function (option, idx) {
            if (Array.isArray(option)) {
              var itemName = option[0];
              var itemValue = option[1];
            } else {
              var itemName = option;
              var itemValue = option;
            }

            var additionalAttrs = {};
            var dataAttributes = option[2];
            if (dataAttributes != null) {
              for (var i = 0; i < dataAttributes.length; i++) {
                if (dataAttributes[i].name != undefined)
                  additionalAttrs[dataAttributes[i].name] =
                    dataAttributes[i].value;
              }
            }

            return (
              <option
                {...additionalAttrs}
                key={me.state.fieldName + 'select-option' + idx}
                id={'select-option' + idx}
                className="mdc-list-item"
                role="option"
                name={htmlFieldName}
                data-field-name={actualFieldName}
                data-value={itemValue}
                value={itemValue || ''}
                onClick={me.state.handleChange}
              >
                {itemName}
              </option>
            );
          });

        let selectLabelView;
        let selectClass;
        if (this.state.showLabel) {
          selectLabelView = (
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={me.state.fieldName}
            >
              {this.state.title}
            </label>
          );
          selectClass = 'pm-selectlabel';
        } else {
          selectClass = 'pm-margin-top-15';
        }

        var editField = (
          <div
            className={
              me.state.multiple ? 'pm-select pm-select-multiple' : 'pm-select'
            }
          >
            {selectLabelView}
            <select
              id={me.state.fieldName}
              className="pm-default-select2-menu data-block-select2 mdc-select pm-select-small"
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={me.state.value || ''}
              onChange={me.state.handleChange}
              multiple={me.state.multiple ? 'multiple' : null}
              ref={(input) => {
                this.input = input;
              }}
            >
              {selectMenuOptions}
            </select>
          </div>
        );
      }
    } else if (this.state.type == 'textarea' || this.state.type == 'memo') {
      var editField = (
        <div
          className="mdc-text-field mdc-text-field--multiline"
          data-mdc-auto-init="MDCTextField"
        >
          <label
            onClick={that.handleEditLabelClick}
            htmlFor={this.state.fieldName}
            className="mdc-floating-label memo-label"
          >
            {this.state.title}
          </label>
          <textarea
            rows="1"
            id={this.state.fieldName}
            className={
              'mdc-text-field__input memo-input ' +
              (this.state.fieldName.includes('description')
                ? 'marketing_description'
                : '')
            }
            name={htmlFieldName}
            data-field-name={actualFieldName}
            value={this.state.value || ''}
            onChange={this.state.handleChange}
          ></textarea>
        </div>
      );
    } else if (this.state.type == 'hidden_on_edit') {
      var editField = <div></div>;
    } else if (this.state.type == 'time') {
      var editField = (
        <div
          className={
            'mdc-form-field mdc-form-field--align-end ' +
            this.state.formFieldClassNames
          }
        >
          <div
            className="mdc-text-field pm-margin-none"
            data-mdc-auto-init="MDCTextField"
          >
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={this.state.fieldName}
              className={
                'mdc-floating-label' +
                (String(this.state.value || '').length > 0
                  ? ' mdc-floating-label--float-above'
                  : '')
              }
            >
              {this.state.title}
            </label>
            <input
              ref={(input) => {
                this.input = input;
              }}
              type="text"
              id={this.state.fieldName}
              className="mdc-text-field__input pm-timepicker-field"
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={this.state.value || ''}
              onChange={this.state.handleChange}
            />
          </div>
        </div>
      );
    } else if (this.state.type == 'date') {
      var editField = (
        <div
          className={
            'mdc-form-field mdc-form-field--align-end ' +
            this.state.formFieldClassNames
          }
        >
          <div
            className="mdc-text-field pm-margin-none"
            data-mdc-auto-init="MDCTextField"
          >
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={this.state.fieldName}
              className={
                'mdc-floating-label' +
                (String(this.state.value || '').length > 0
                  ? ' mdc-floating-label--float-above'
                  : '')
              }
            >
              {this.state.title}
            </label>
            <input
              ref={(input) => {
                this.input = input;
              }}
              type="text"
              id={this.state.fieldName}
              className="mdc-text-field__input pm-datepicker-field"
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={this.state.value || ''}
              onChange={this.state.handleChange}
            />
          </div>
        </div>
      );
    } else if (this.state.type == 'combodate') {
      var editField = (
        <div
          className={
            'mdc-form-field mdc-form-field--align-end ' +
            this.state.formFieldClassNames
          }
        >
          <div
            className="mdc-text-field pm-margin-none"
            data-mdc-auto-init="MDCTextField"
          >
            <label
              onClick={that.handleEditLabelClick}
              className={
                'mdc-floating-label' +
                (String(this.state.value || '').length > 0
                  ? ' mdc-floating-label--float-above'
                  : '')
              }
              htmlFor={this.state.fieldName}
            >
              {this.state.title}
            </label>
            <input
              ref={(input) => {
                this.input = input;
              }}
              type="text"
              id={this.state.fieldName}
              className="mdc-text-field__input pm-datetimepicker-field"
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={this.state.value || ''}
              onChange={this.state.handleChange}
            />
          </div>
        </div>
      );
    } else if (this.state.type == 'readonly') {
      return (
        <div className="pm-flex pm-flex-col pm-margin-bottom-8">
          <div className="pm-display-only">
            <span className="mdc-expansion__item-title">
              {this.state.title}
            </span>
            <span className="mdc-expansion__item-value">
              {this.state.displayValue}
            </span>
          </div>
        </div>
      );
    } else if (this.state.type == 'note') {
      // The view in display and edit mode are the same
    } else if (this.state.type == 'bubble') {
      return <div className="pm-label">{this.state.displayValue}</div>;
    } else if (this.state.type == 'number') {
      var editField = (
        <div
          className={
            'mdc-form-field mdc-form-field--align-end ' +
            this.state.formFieldClassNames
          }
        >
          <div
            className="mdc-text-field pm-margin-none"
            data-mdc-auto-init="MDCTextField"
          >
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={this.state.fieldName}
              className="mdc-floating-label"
            >
              {this.state.title}
            </label>
            <input
              id={this.state.fieldName}
              ref={(input) => {
                this.input = input;
              }}
              type="number"
              data-title={this.state.title}
              className={'mdc-text-field__input'}
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={this.state.value || ''}
              onChange={this.state.handleChange}
            />
          </div>
        </div>
      );
    } else if (this.state.type == 'currency') {
      var editField = (
        <div
          className={
            'mdc-form-field mdc-form-field--align-end ' +
            this.state.formFieldClassNames
          }
        >
          <div
            className="mdc-text-field pm-margin-none"
            data-mdc-auto-init="MDCTextField"
          >
            <label
              onClick={that.handleEditLabelClick}
              htmlFor={this.state.fieldName}
              className="mdc-floating-label"
            >
              {this.state.title}
            </label>
            <input
              id={this.state.fieldName}
              ref={(input) => {
                this.input = input;
              }}
              type="text"
              data-title={this.state.title}
              className={'mdc-text-field__input'}
              name={htmlFieldName}
              data-field-name={actualFieldName}
              value={this.state.value || ''}
              onChange={this.state.handleChange}
            />
          </div>
        </div>
      );
    } else {
      var fieldType =
        typeof fieldType === 'undefined' ? 'text' : this.state.type;
      var moneyClass = this.state.title == 'Amount' ? ' pm-format-money' : '';
      var datePickerClass = ['Date', 'Due Date'].includes(this.state.title)
        ? ' pm-datepicker-field'
        : '';
      var textFieldClass = 'mdc-floating-label';
      if (
        (typeof this.state.value == 'string' ||
          typeof this.state.value == 'number') &&
        this.state.value != ''
      ) {
        textFieldClass += ' mdc-floating-label--float-above';
      }
      if (this.state.placeholder) {
        textFieldClass += ' mdc-floating-label--always-float-above';
      }
      if (this.props.maintenancePage) {
        var editField = (
          <div
            className={
              'mdc-form-field mdc-form-field--align-end ' +
              this.state.formFieldClassNames
            }
          >
            <div
              className="mdc-text-field pm-margin-none"
              data-mdc-auto-init="MDCTextField"
            >
              <label
                onClick={that.handleEditLabelClick}
                htmlFor={fieldType + '-field'}
                className={textFieldClass}
              >
                {this.state.title}
              </label>
              <input
                ref={(input) => {
                  this.input = input;
                }}
                type={fieldType}
                data-title={this.state.title}
                className={
                  'mdc-text-field__input' + moneyClass + datePickerClass
                }
                name={htmlFieldName}
                data-field-name={actualFieldName}
                data-groupkey={this.state.groupKey}
                defaultValue={this.state.value || ''}
                onBlur={this.state.handleChange}
              />
            </div>
          </div>
        );
      } else {
        var editField = (
          <div
            className={
              'mdc-form-field mdc-form-field--align-end ' +
              this.state.formFieldClassNames
            }
          >
            <div
              className="mdc-text-field pm-margin-none"
              data-mdc-auto-init="MDCTextField"
            >
              <label
                onClick={that.handleEditLabelClick}
                htmlFor={this.state.htmlForId || this.state.fieldName}
                className={textFieldClass}
              >
                {this.state.title}
              </label>
              <input
                ref={(input) => {
                  this.input = input;
                }}
                type={fieldType}
                data-title={this.state.title}
                className={
                  'mdc-text-field__input' + moneyClass + datePickerClass
                }
                name={htmlFieldName}
                data-field-name={actualFieldName}
                data-groupkey={this.state.groupKey}
                value={this.state.value || ''}
                placeholder={this.state.placeholder || ''}
                onChange={this.state.handleChange}
                onBlur={this.state.handleBlur}
                id={this.state.htmlForId || this.state.fieldName}
              />
            </div>
          </div>
        );
      }
    }

    var primaryEmailOrPhone =
      this.props.primaryEmailPhone === 'primary_email' ||
      this.props.primaryEmailPhone === 'primary_phone';
    var primaryInfoId = '';
    if (primaryEmailOrPhone) {
      switch (this.props.primaryEmailPhone) {
        case 'primary_email':
          primaryInfoId = 'add-email';
          break;
        case 'primary_phone':
          primaryInfoId = 'add-phone';
          break;
        default:
      }
    }

    var secondaryEmailOrPhone =
      this.props.primaryEmailPhone === 'email' ||
      this.props.primaryEmailPhone === 'phone';
    var secondaryInfoId = '';
    if (secondaryEmailOrPhone) {
      switch (this.props.primaryEmailPhone) {
        case 'email':
          secondaryInfoId = 'remove-email';
          break;
        case 'phone':
          secondaryInfoId = 'remove-phone';
          break;
        default:
      }
    }

    if (typeof this.state.editOnly !== 'undefined' && this.state.editOnly) {
      return (
        <div
          className={
            'pm-flex pm-flex-col ' +
            (this.state.pmFlexClassNames || 'pm-margin-bottom-8') +
            (this.state.fullWidth ? ' pm-width-full' : '')
          }
          style={style}
        >
          {/* Editing form field */}
          <div className="pm-edit">
            <div className="pm-flex pm-flex-nowrap pm-flex-align-center">
              {editField}
              {primaryEmailOrPhone && this.props.groupType === 'tenant' && (
                <button
                  data-id={this.props.emailPhoneId}
                  className="mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--blue1 pm-modal-button"
                  data-mdc-auto-init="MDCRipple"
                  id={primaryInfoId}
                  onClick={this.props.onEmailPhoneAdd}
                >
                  <i className="mdi mdi-plus"></i>
                </button>
              )}
              {secondaryEmailOrPhone && this.props.groupType === 'tenant' && (
                <button
                  data-id={this.props.emailPhoneId}
                  className="mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--red1 pm-modal-button"
                  data-mdc-auto-init="MDCRipple"
                  id={secondaryInfoId}
                  onClick={this.props.onEmailPhoneRemove}
                >
                  <i className="mdi mdi-close"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      );
    } else if (
      typeof this.state.displayOnly !== 'undefined' &&
      this.state.displayOnly
    ) {
      return (
        <div className="pm-flex pm-flex-col pm-margin-bottom-8">
          <div className="pm-display-only">
            <span className="mdc-expansion__item-title">
              {this.state.title}
            </span>
            <span className="mdc-expansion__item-value">
              {this.state.displayValue}
            </span>
          </div>
        </div>
      );
    }

    var marginStyle = {};
    var additionalClasses = '';
    if (this.state.isTransactionItemComponent) {
      additionalClasses = 'pm-margin-bottom-8';
      marginStyle = { marginRight: '2%', maxWidth: '14.0625rem' };
    }
    if (this.props.widthOverride) {
      marginStyle['width'] = this.props.widthOverride;
    }

    let displayClassNames = 'pm-display';
    if (this.state.type === 'note') {
      displayClassNames = 'pm-display pm-display-note';
      let leaseRow =
        typeof this.props.groupType != 'undefined' &&
        this.props.groupType.toLowerCase().indexOf('lease') != -1;
      const entityId =
        typeof this.props.groupType != 'undefined' &&
        this.props.groupType == 'owner'
          ? this.props.entityId
          : null;
      const showNotesBody =
        this.state.notes.length > 0 && !this.state.showNotesForm;
      const showButton =
        this.state.notes.length > 1 && !this.state.showNotesForm;
      let notesBody;
      if (this.state.expanded) {
        notesBody = this.state.notes.map((note, index) => (
          <div class="notesAccordion" key={index}>
            <div class="pm-flex pm-flex-nowrap pm-flex-align-center">
              <div class="pm-flex-col pm-flex-grow">
                <div class="pm-display-values--value">{note.value}</div>
                <div class="pm-flex pm-flex-nowrap pm-flex-space-between">
                  <span class="notesDetail">{note.loginEmail}</span>
                  <span class="notesDetail">{note.createdAt}</span>
                </div>
              </div>

              <div id="more-options-icon-undefined" class="mdc-menu-anchor">
                <button
                  id="pm-sidebarmenu-button_1"
                  data-id={note.id}
                  data-lease-id={leaseRow ? this.props.leaseId : null}
                  data-entity-id={entityId}
                  data-custom-field-id={this.props.customFieldId}
                  type="button"
                  class="pm-menu-button mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--grey4 mdc-ripple-upgraded"
                  data-mdc-auto-init="MDCRipple"
                  onClick={this.handleNoteDeleteClick}
                >
                  <i class="mdi mdi-trash-can-outline"></i>
                </button>
              </div>
            </div>
          </div>
        ));
      } else if (this.state.notes.length > 0) {
        let note = this.state.notes[0];
        notesBody = (
          <div class="notesAccordion">
            <div class="pm-flex pm-flex-nowrap pm-flex-align-center">
              <div class="pm-flex-col pm-flex-grow">
                <div class="pm-display-values--value">{note.value}</div>
                <div class="pm-flex pm-flex-nowrap pm-flex-space-between">
                  <span class="notesDetail">{note.loginEmail}</span>
                  <span class="notesDetail">{note.createdAt}</span>
                </div>
              </div>

              <div id="more-options-icon-undefined" class="mdc-menu-anchor">
                <button
                  id="pm-sidebarmenu-button_1"
                  data-id={note.id}
                  data-lease-id={leaseRow ? this.props.leaseId : null}
                  data-entity-id={entityId}
                  data-custom-field-id={this.props.customFieldId}
                  type="button"
                  class="pm-menu-button mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--grey4 mdc-ripple-upgraded"
                  data-mdc-auto-init="MDCRipple"
                  onClick={this.handleNoteDeleteClick}
                >
                  <i class="mdi mdi-trash-can-outline"></i>
                </button>
              </div>
            </div>
          </div>
        );
      }

      var display = (
        <div class="pm-flex pm-flex-col">
          <div class="pm-display-note">
            <div class="pm-flex pm-flex-nowrap pm-flex-align-bottom">
              <div class="pm-flex pm-flex-col pm-flex-grow notesWrap">
                <div class="pm-flex pm-flex-space-between notesHead">
                  <div class="mdc-expansion__item-title data-block-title">
                    {this.state.title}
                    {this.state.notes.length > 0
                      ? ` (${this.state.notes.length})`
                      : ``}
                  </div>
                  {!this.state.showNotesForm && (
                    <div class="pm-flex">
                      <button
                        type="button"
                        class="mdc-button pm-text-color--blue pm-buttonTiny"
                        onClick={this.handleAddNoteClick}
                      >
                        <span class="mdi mdi-plus"></span>
                        Add
                      </button>
                    </div>
                  )}
                </div>

                <div class="pm-flex pm-flex-col notesBody">
                  {this.state.showNotesForm && (
                    <div class="notesForm pm-flex pm-flex-col">
                      <TextArea
                        field={{
                          name: 'note[value]',
                          label: 'Value',
                        }}
                        mode="edit"
                      />
                      <div class="notesForm_footer pm-flex">
                        <button
                          type="button"
                          class="mdc-button pm-text-color--grey5 pm-buttonTiny"
                          onClick={() => {
                            this.setState({ showNotesForm: false }, () => {
                              window.dispatchEvent(new Event('resize'));
                            });
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          id="note-save-button"
                          type="button"
                          class="mdc-button pm-text-color--blue pm-buttonTiny"
                          data-lease-id={leaseRow ? this.props.leaseId : null}
                          data-entity-id={entityId}
                          data-custom-field-id={this.props.customFieldId}
                          onClick={this.handleNoteSaveClick}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                  {showNotesBody && (
                    <div class="notesAccordion_wrap">
                      <div class="notesAccordion_outer">
                        <div class="notesAccordion_inner">{notesBody}</div>
                      </div>
                    </div>
                  )}
                  {showButton && (
                    <button
                      type="button"
                      class="mdc-button pm-text-color--blue pm-buttonTiny notesAccordion_button"
                      onClick={() => {
                        this.setState(
                          { expanded: !this.state.expanded },
                          () => {
                            window.dispatchEvent(new Event('resize'));
                          }
                        );
                      }}
                    >
                      <span
                        className={`mdi ${
                          this.state.expanded
                            ? 'mdi-chevron-up'
                            : 'mdi-chevron-down'
                        }`}
                      ></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.fullText) {
      var display;
      if (this.state.fieldName == 'unit[description]') {
        let displayStyle = { whiteSpace: 'pre-wrap' };
        display = (
          <div className="pm-display datum-block-full-text">
            <p
              className="pm-margin-top-none"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {this.state.value}
            </p>
          </div>
        );
      } else if (
        this.state.fieldName == 'building[marketing]' ||
        this.state.fieldName == 'building[marketing_phone]' ||
        this.state.fieldName == 'building[marketing_email]'
      ) {
        display = (
          <div className="pm-flex pm-flex-col pm-margin-bottom-20 datum-block-display">
            <div className="pm-display">
              <div className="pm-display-values--label">{this.state.title}</div>
              <div className="pm-display-values--value">
                {this.state.displayValue}
              </div>
            </div>
          </div>
        );
      }
    } else if (this.state.propertiesPage) {
      if (this.state.type == 'currency') {
        var display = (
          <div className="pm-flex pm-flex-col pm-margin-bottom-20 datum-block-display">
            <div className="pm-display">
              <div className="pm-display-values--label">{this.state.title}</div>
              <div className="pm-display-values--value">
                {this.state.displayValue}
              </div>
            </div>
          </div>
        );
      } else if (
        this.state.fieldName ==
        'building[custom_domain_attributes][domain_name]'
      ) {
        var display = (
          <div className="pm-flex pm-flex-col pm-margin-bottom-20 datum-block-display">
            <div className="pm-display">
              <div className="pm-display-values--label">{this.state.title}</div>
              <div id="custom-domain-anchor"></div>
            </div>
          </div>
        );
      } else {
        var display = (
          <div className="pm-flex pm-flex-col pm-margin-bottom-20 datum-block-display">
            <div className="pm-display">
              <div className="pm-display-values--label">{this.state.title}</div>
              <div className="pm-display-values--value">
                {this.state.displayValue +
                  (this.state.title.toLowerCase() == 'area' &&
                  this.state.displayValue != ''
                    ? ` ${window.areaUnit}`
                    : '')}
              </div>
            </div>
          </div>
        );
      }
    } else {
      let leaseRow = this.props.groupName == 'Lease Information';
      var display = (
        <div
          data-lease-id={leaseRow ? this.props.leaseId : null}
          className={displayClassNames}
        >
          <span
            className="mdc-expansion__item-title data-block-title"
            data-lease-id={leaseRow ? this.props.leaseId : null}
            data-custom-field-id={this.props.customFieldId}
            onClick={
              this.state.type == 'note' ? this.props.handleNoteClick : null
            }
          >
            {this.state.title}
          </span>
          {/^(http|https):\/\//.test(this.state.displayValue) ? (
            <a
              href={this.state.displayValue}
              target="_blank"
              className={
                'mdc-expansion__item-value data-block-value' +
                (this.state.fieldName &&
                this.state.fieldName.includes('maintenance_request') &&
                this.state.title == 'Description'
                  ? ' maintenance_description_value'
                  : '')
              }
              data-lease-id={leaseRow ? this.props.leaseId : null}
              data-custom-field-id={this.props.customFieldId}
              onClick={
                this.state.type == 'note' ? this.props.handleNoteClick : null
              }
            >
              {this.state.displayValue}
            </a>
          ) : (
            <span
              title={this.state.displayValue}
              className={
                'mdc-expansion__item-value data-block-value' +
                (this.state.fieldName &&
                this.state.fieldName.includes('maintenance_request') &&
                this.state.title == 'Description'
                  ? ' maintenance_description_value'
                  : '')
              }
              data-lease-id={leaseRow ? this.props.leaseId : null}
              data-custom-field-id={this.props.customFieldId}
              onClick={
                this.state.type == 'note' ? this.props.handleNoteClick : null
              }
            >
              {this.state.displayValue}
            </span>
          )}
        </div>
      );
    }

    return (
      <div
        className={
          'pm-flex pm-flex-col ' +
          this.state.pmFlexClassNames +
          ' ' +
          additionalClasses +
          ' ' +
          (this.state.fullWidth ? 'pm-flex-grow' : '')
        }
        style={marginStyle}
      >
        {/* Display text */}
        {display}

        {/* Editing form field */}
        {this.state.type != 'note' && (
          <div
            className="pm-edit"
            style={{ marginBottom: this.state.propertiesPage ? 14 : 0 }}
          >
            <div className="pm-flex pm-flex-nowrap pm-flex-align-center">
              {editField}
              {primaryEmailOrPhone &&
                (this.props.groupType === 'tenant' ||
                  this.props.groupType === 'vendor') && (
                  <button
                    data-id={this.props.emailPhoneId}
                    className="mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--blue1 pm-modal-button"
                    data-mdc-auto-init="MDCRipple"
                    id={primaryInfoId}
                    onClick={this.props.onEmailPhoneAdd}
                  >
                    <i className="mdi mdi-plus"></i>
                  </button>
                )}
              {secondaryEmailOrPhone &&
                (this.props.groupType === 'tenant' ||
                  this.props.groupType === 'vendor') && (
                  <button
                    data-id={this.props.emailPhoneId}
                    className="mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--red1 pm-modal-button"
                    data-mdc-auto-init="MDCRipple"
                    id={secondaryInfoId}
                    onClick={this.props.onEmailPhoneRemove}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
